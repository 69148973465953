import React, { useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import ExploreServices from "./ExploreServices";
import BookModal from "./BookModal";

const ElectricianNearMe = () => {
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>
            Expert Electrician Services in your city | Reliable & Affordable
            electrical Solutions in your city | Quality Electrical Services Near
            You{" "}
          </title>
          <meta
            name="description"
            content="Reliable electrician services for all your needs! From residential to commercial projects, our skilled team ensures quality workmanship, safety, and efficiency. Contact us now on Joboy for prompt and professional electrical solutions."
          />
          <meta
            name="keywords"
            content="electrician service near me, electrical contracting companies near me, electrical contractors  near me, electrician required  near me "
          />
        </Helmet>
      </HelmetProvider>
      <section>
        <img
          src="../joboyimages/services/electrician-hero.jpg"
          className="img-fluid"
          alt="Expert Electricians Near You"
        />
      </section>

      <section>
        <div className="container text-center pb40">
          <h1 className="serviceh1">GET EXPERIENCED ELECTRICIANS NEAR YOU</h1>
          <p>
            Expert electrical repairs near you, at your convenient time. Book
            now for on-demand repairs, with a service warranty, at low costs,
            available 24/7.
          </p>
        </div>
        <div className="container pt40 pb40">
          <h2 className="text-center heading_h2">
            QUICK LINKS TO ELECTRICIAN SERVICES IN CITIES AROUND YOU
          </h2>
          <div className="row justify-content-center">
            <ul className="joboy-city">
              <li>
                {" "}
                <a href={`/in/electrician-in-chennai`}>
                  <img
                    src="../joboyimages/chennai.png"
                    className="img-icon"
                    alt="Best Electricians in Chennai"
                  />
                  <div>Electricians in </div>
                  <div>Chennai</div>
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href={`/in/electrician-in-kochi`}>
                  <img
                    src="../joboyimages/Kochi.png"
                    className="img-icon"
                    alt="Best Electricians in Kochi"
                  />
                  <div>Electricians in </div>
                  <div>Kochi</div>
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href={`/in/electrician-in-bangalore`}>
                  <img
                    src="../joboyimages/bangalore.png"
                    className="img-icon"
                    alt="Best Electricians in Bengaluru"
                  />
                  <div>Electricians in </div>
                  <div>Bengaluru</div>
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href={`/in/electrician-in-trivandrum`}>
                  <img
                    src="../joboyimages/trivandrum.png"
                    className="img-icon"
                    alt="Best Electricians in Trivandrum"
                  />
                  <div>Electricians in</div>
                  <div>Trivandrum</div>
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href={`/in/electrician-in-mumbai`}>
                  <img
                    src="../joboyimages/mumbai.png"
                    className="img-icon"
                    alt="Best Electricians in Mumbai"
                  />
                  <div>Electricians in</div>
                  <div>Mumbai</div>
                </a>
              </li>
              <li>
                {" "}
                <a href={`/in/electrician-in-delhi`}>
                  <img
                    src="../joboyimages/Delhi.png"
                    className="img-icon"
                    alt="Best Electricians in Delhi"
                  />
                  <div>Electricians in </div>
                  <div>Delhi</div>
                </a>
              </li>
              <li>
                {" "}
                <a href={`/in/electrician-in-kozhikode`}>
                  <img
                    src="../joboyimages/kozhikode.png"
                    className="img-icon"
                    alt="Best Electricians in Kozhikode"
                  />
                  <div>Electricians in </div>
                  <div>Kozhikode</div>
                </a>
              </li>

              <li>
                {" "}
                <a href={`/in/electrician-in-thrissur`}>
                  <img
                    src="../joboyimages/thrissur.png"
                    className="img-icon"
                    alt="Best Electricians in Thrissur"
                  />
                  <div>Electricians in </div>
                  <div>Thrissur</div>
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href={`/in/electrician-in-hyderabad`}>
                  <img
                    src="../joboyimages/hyderabad.png"
                    className="img-icon"
                    alt="Best Electricians in Hyderabad"
                  />
                  <div>Electricians in </div>
                  <div>Hyderabad</div>
                </a>
              </li>
              <li>
                {" "}
                <a href={`/ae/electrician-in-dubai`}>
                  <img
                    src="../joboyimages/dubai.png"
                    className="img-icon"
                    alt="Best Electricians in Dubai"
                  />
                  <div>Electricians in</div>
                  <div>Dubai</div>
                </a>{" "}
              </li>
              <li>
                {" "}
                <a href={`/ae/electrician-in-abudhabi`}>
                  <img
                    src="../joboyimages/Abudhabi.png"
                    className="img-icon"
                    alt="Best Electricians in Abu Dhabi"
                  />
                  <div>Electricians in </div>
                  <div>Abu Dhabi</div>
                </a>
              </li>
              <li>
                {" "}
                <a href={`/ae/electrician-in-sharjah`}>
                  <img
                    src="../joboyimages/Sharjah.png"
                    className="img-icon"
                    alt="Best Electricians in Sharjah"
                  />
                  <div>Electricians in </div>
                  <div>Sharjah</div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section>
        <div className="container blue-bg pt40 pb40">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <h5 className="download-text white-font">
                  {" "}
                  GET A BETTER EXPERIENCE ON THE JOBOY MOBILE APP. DOWNLOAD NOW!
                </h5>
              </div>
              <div clas="col-md-6">
                <div className="row">
                  <div className="col-md-6 form-inline">
                    <div className="form-group p-1">
                      {" "}
                      <a
                        href="https://apps.apple.com/us/app/joboy-home-services-repairs/id1270174460"
                        className="btn btn-market btn--with-shadow"
                      >
                        {" "}
                        <img
                          className="utouch-icon"
                          src="../joboyimages/ios.png"
                          alt="Joboy on Apple app store"
                        />
                        <div className="text">
                          {" "}
                          <span className="sup-title">Download from</span>{" "}
                          <span className="title">App Store</span>{" "}
                        </div>
                      </a>
                    </div>
                  </div>
                  <div className="col-md-6 form-inline">
                    <div className="form-group p-1">
                      {" "}
                      <a
                        href="https://play.google.com/store/apps/details?id=com.serville.joboy"
                        className="btn btn-market btn--with-shadow"
                      >
                        {" "}
                        <img
                          className="utouch-icon"
                          src="../joboyimages/google-play.svg"
                          alt="Joboy on Google play store"
                        />
                        <div className="text">
                          {" "}
                          <span className="sup-title">Download from</span>{" "}
                          <span className="title">Google Play</span>{" "}
                        </div>
                      </a>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb40 pt40">
        <div className="container text-center">
          <h2>EXPERT ELECTRICAL SERVICES IN YOUR CITY</h2>
          <p className="mb-5">
            Our experienced electricians can take care of all your electrical
            problems from fixing a light bulb to complex installations and
            repairs. They can also help with fixing your appliances, hanging a
            painting on the wall, or your festival lighting, periodic
            maintenance, for your home, at affordable prices, using best quality
            spares and materials.
          </p>

          <div className="row justify-content-center">
            <div className="col-lg-12"></div>
            <div className="col-lg-4 col-md-6">
              <div className="featured-item pd-bottom-30">
                <div className="thumb">
                  <img
                    src="../joboyimages/services/light-fixing.jpg"
                    alt="Light fixtures"
                  />
                </div>
                <div className="featured-item-meta">
                  <h3>LIGHT FIXTURES</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="featured-item pd-bottom-30">
                <div className="thumb">
                  <img
                    src="../joboyimages/services/fuse-boxes.jpg"
                    alt="Switch boards"
                  />
                </div>
                <div className="featured-item-meta">
                  <h3>SWITCH BOARDS</h3>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="featured-item pd-bottom-30">
                <div className="thumb">
                  <img
                    src="../joboyimages/services/light-fixtures.jpg"
                    alt="electrical ligthing"
                  />
                </div>
                <div className="featured-item-meta">
                  <h3>FESTIVAL LIGHTING</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container text-center">
          <div className="row">
            <div className="col-sm-4 pt-5">
              <ul className="features-list">
                <div className="iconbox_icon">
                  {" "}
                  <img
                    className="first_icon"
                    src="../joboyimages/Easy-Scheduling.png"
                    alt="Easy to book"
                  />{" "}
                </div>
                <li className="single-feature-item">
                  <h5> Your Convenient Time</h5>
                  <span className="text">
                    Your time is precious. Leave the tough work to us.
                  </span>{" "}
                </li>
                <div className="iconbox_icon">
                  {" "}
                  <img
                    className="first_icon"
                    src="../joboyimages/Verified-Professionals.png"
                    alt="Background verified"
                  />{" "}
                </div>
                <li className="single-feature-item">
                  <h5>Verified Service Partners</h5>
                  <span className="text">
                    Your safety and security is important for us.
                  </span>{" "}
                </li>
                <div className="iconbox_icon">
                  {" "}
                  <img
                    className="first_icon"
                    src="../joboyimages/ServiceWarranty.png"
                    alt="Warranty on services"
                  />{" "}
                </div>
                <li className="single-feature-item">
                  <h5>Service Warranty</h5>
                  <span className="text">
                    Get upto 60 days service warranty ensuring peace of mind.
                  </span>{" "}
                </li>
                <div className="iconbox_icon">
                  {" "}
                  <img
                    className="first_icon"
                    src="../joboyimages/TransparentPricing.png"
                    alt="Pre-defined pricing"
                  />{" "}
                </div>
                <li className="single-feature-item">
                  <h5>Transparent Pricing</h5>
                  <span className="text">
                    Know in advance what you are paying for.
                  </span>{" "}
                </li>
                <div className="iconbox_icon">
                  {" "}
                  <img
                    className="first_icon"
                    src="../joboyimages/Secure-Payments.png"
                    alt="Many payment options"
                  />{" "}
                </div>
                <li className="single-feature-item">
                  <h5>Online Payments </h5>
                  <span className="text">
                    Don't carry cash? No problem, pay online.
                  </span>{" "}
                </li>
                <div className="iconbox_icon">
                  {" "}
                  <img
                    className="first_icon"
                    src="../joboyimages/Support.png"
                    alt="Best customer care"
                  />{" "}
                </div>
                <li className="single-feature-item">
                  <h5>24 x 7 Support</h5>
                  <span className="text">
                    Reach us anytime. We are here to support.
                  </span>{" "}
                </li>
              </ul>
            </div>
            <div className="col-sm-8 text-left">
              <div className="p-5">
                <h3>Why book electricians from Joboy?</h3>
                <div>
                  <p>
                    We bring you background verified, experienced, electricians
                    to you, at your most convenient time, with an added service
                    warranty, to give you more confidence. You can be sure that
                    we will give you the best services, and in case something
                    goes wrong with what we have done, we will correct it at our
                    cost. Our electrical repair service costs are affordable,
                    and you get to know in advance what you will be paying for.
                    You also have the option to pay by cash or pay online. Rest
                    assured, we are the best when it come to your home repairs.
                  </p>
                  <p className="mt-3 mb-5">
                    Book a service online easily, sit back and relax, and let
                    our experts take care of your home.
                  </p>
                </div>
                <div className="clearfix"></div>
                <div className="row">
                  <div className="col-sm-6">
                    <ul className="td-list mb-5">
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Best Electricians Near Me"
                          />
                        </i>
                        HVAC electrical fitting
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Electrical Repairs Near Me"
                          />
                        </i>
                        Switch and sockets repairs
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Electrical wiring fixes"
                          />
                        </i>
                        Electrical wiring fixes
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Inverter and stabilizer services"
                          />
                        </i>
                        Inverter and stabilizer services
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Fan and air cooler repairs"
                          />
                        </i>
                        Fan and air cooler repairs
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="MCB and fuse box repairs"
                          />
                        </i>
                        Electricians Near Me
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6">
                    <ul className="td-list mb-5">
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Switch and sockets repairs"
                          />
                        </i>
                        MCB and fuse box repairs
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Light fixtures repairs"
                          />
                        </i>
                        Light fixtures repairs
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Room heater repairs"
                          />
                        </i>
                        Room heater repairs
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="HVAC electrical fitting"
                          />
                        </i>
                        Accessories installation
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Wall drilling and hanging"
                          />
                        </i>
                        Wall Drilling & Hanging
                      </li>
                      <li>
                        <i>
                          <img
                            src="../joboyimages/tck-mark.png"
                            alt="Accessories installation"
                          />
                        </i>
                        Electrical Repairs Near Me
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ExploreServices />

      <BookModal />
    </div>
  );
};

export default ElectricianNearMe;
