import React, { useEffect, useState } from "react";
import axios from "axios";
import DOMPurify from "dompurify";

import { Helmet, HelmetProvider } from "react-helmet-async";

import "@fortawesome/fontawesome-free/css/all.min.css";

import "./services.css";

import { useSelector } from "react-redux";

const pricelabel_30_min = [
  "First 1 Hr",
  "Upto 1.5 Hrs",
  "Upto 2 Hrs",
  "Upto 2.5 Hrs",
  "Upto 3 Hrs",
  "Upto 3.5 Hrs",
  "Upto 4 Hrs",
];

const pricelabel_1_hr = ["First 1 Hr", "Upto 2 Hrs", "Upto 3 Hrs"];

const pricelabel_1_day = ["One Day Unit"];
const pricelabel_fixed = ["Fixed Unit"];

function ServicesDetails() {
  const { apiKey } = useSelector((state) => state.settings);

  const pathParts = window.location.pathname.split("/");
  const country_alpha = pathParts[1];
  const seo_url = pathParts[2];

  const [serviceDetails, setServiceDetails] = useState(null);

  const [coveredArea, setCoveredArea] = useState(null);
  //const [error, setError] = useState(null);

  useEffect(() => {
    const fetchServiceDetails = async () => {
      try {
        const response = await axios.post(
          `https://joboy.${country_alpha}/api/service/getServiceDet`,
          new URLSearchParams({
            seo_url: seo_url,
          }),
          {
            headers: {
              Authorization: "Basic YWRtaW46MTIzNA==",
              session: apiKey,
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );

        setServiceDetails(response.data.data);
        setCoveredArea(response.data.area_list);
        // console.log(response.status);
      } catch (error) {
        console.error("Error fetching data:", error);
        // setError("Failed to fetch service details");
      }
    };

    fetchServiceDetails();
  }, [seo_url, country_alpha, apiKey]);

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }

  // if (!serviceDetails) {
  //   return <div>Loading...</div>;
  // }

  const [categoryPrice, setCategoryPrice] = useState([]);
  //const [unit, setUnit] = useState("Dynamic");
  const [priceLabel, setPriceLabel] = useState([]);
  //const [limit, setLimit] = useState(0);

  useEffect(() => {
    // Check if serviceDetails is available
    if (serviceDetails && serviceDetails.category_price) {
      setCategoryPrice(JSON.parse(serviceDetails.category_price || "[]"));

      const categoryUnit = parseInt(serviceDetails.category_unit, 10);

      switch (categoryUnit) {
        case 1:
          // setUnit("30 Minutes");
          // setLimit(6);
          setPriceLabel(pricelabel_30_min);
          break;
        case 2:
          // setUnit("1 Hour");
          // setLimit(3);
          setPriceLabel(pricelabel_1_hr);
          break;
        case 3:
          // setUnit("One Day");
          // setLimit(0);
          setPriceLabel(pricelabel_1_day);
          break;
        case 4:
          // setUnit("Fixed");
          // setLimit(0);
          setPriceLabel(pricelabel_fixed);
          break;
        default:
          // setUnit("Dynamic");
          // setLimit(0);
          setPriceLabel([]);
      }
    }
  }, [serviceDetails]);

  if (!serviceDetails) {
    return <div style={{ margin: 100 }}>
        <div id="loading">
          <img id="loading-image" src="https://d27vg8jo26ejl7.cloudfront.net/images/preloader.gif" alt="Loading..." />
        </div>
      </div>; // Or return null if you prefer
  }

  const today = new Date().toISOString().split("T")[0];
  const offer =
    serviceDetails.offer_required == 1 &&
    today >= serviceDetails.offer_start &&
    today <= serviceDetails.offer_end;

    
  const offerType = serviceDetails.offer_type;
  const offerAmount = serviceDetails.offer_amount;

  let currency;


  if (serviceDetails.cat_country_id == 1) {
    currency = 'RS';
  } else if (serviceDetails.cat_country_id == 5) {
    currency = 'AED'; 
  } else if (serviceDetails.cat_country_id == 3) {
    currency = 'CA'; 
  } else if (serviceDetails.cat_country_id == 4) {
    currency = 'CA'; 
  } else if (serviceDetails.cat_country_id == 2) {
    currency = 'AZ'; 
  } else {
    currency = 'CA';
  }

  let discountRate = 0;
  let catPrice = 0;

  const rateTermsCondition = JSON.parse(
    serviceDetails.rate_terms_condition || "{}"
  );
  const cancellationDesc = JSON.parse(serviceDetails.cancellationDesc || "{}");

  const renderTerms = (terms) => {
    return Object.keys(terms).map((key, index) => {
      const term = terms[key][0];
      return term ? <li key={index}>{term}</li> : "";
    });
  };

  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <title>{serviceDetails.meta_title}</title>
          <meta name="description" content={serviceDetails.meta_description} />
          <meta name="keywords" content={serviceDetails.meta_keywords} />
        </Helmet>
      </HelmetProvider>
      <section>
        <img
          src="../joboyimages/inner-banner.jpg"
          className="img-fluid"
          alt="Expert Electricians Near You"
        />
      </section>
      <div className="container">
        <h1 className="serviceh1 mb-2">{serviceDetails.service_name}</h1>
        <div className="row">
          <div className="detail-border col-sm-12">
            <ul className="service-offers-ul">
              {serviceDetails.offer1 && (
                <li>
                  <img src="../joboyimages/offer-badge.png" alt="Offer Badge" />{" "}
                  {serviceDetails.offer1}
                </li>
              )}
              {serviceDetails.offer2 && (
                <li>
                  <img src="../joboyimages/offer-badge.png" alt="Offer Badge" />{" "}
                  {serviceDetails.offer2}
                </li>
              )}
            </ul>
          </div>

          <div className="col-sm-12">
            <div className="panel-group" id="accordion" role="tablist">
              <div
                className="panel panel-default"
                style={{
                  display:
                    serviceDetails.rate_terms_condition &&
                    serviceDetails.usecategorypricing !== 1
                      ? "block"
                      : "none",
                }}
              >
                <div className="panel-heading" role="tab" id="headingZero">
                  <h4 className="panel-title">
                    <a
                      className="last collapsed"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseZero"
                      aria-expanded="false"
                      aria-controls="collapseZero"
                    >
                      Rate Chart <span> </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseZero"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingZero"
                  data-parent="#accordion"
                >
                  <div className="panel-body">
                    <div className="rate-chart">
                      <table>
                        <tbody>
                          {/* Handling price_type 0 */}
                          {serviceDetails.price_type === "0" &&
                            categoryPrice.length > 0 &&
                            priceLabel.map((label, i) => {
                              catPrice += Number(categoryPrice[i]);
                              if (offer) {
                                discountRate =
                                  offerType === 0
                                    ? (catPrice * offerAmount) / 100
                                    : offerAmount;
                              }
                              return (
                                <tr key={i}>
                                  <td style={{ textAlign: "left" }}>{label}</td>
                                  {offer ? (
                                    <>
                                      <td style={{ textAlign: "left" }}>
                                        <s>{currency} {Math.round(catPrice)}</s>
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          width: "30%",
                                        }}
                                      >
                                        {currency} {Math.round(catPrice - discountRate)}
                                      </td>
                                    </>
                                  ) : (
                                    <td
                                      style={{
                                        textAlign: "right",
                                        width: "30%",
                                      }}
                                    >
                                      {currency} {Math.round(catPrice)}
                                    </td>
                                  )}
                                </tr>
                              );
                            })}

                          {/* Handling price_type 3 */}
                          {serviceDetails.price_type === "3" &&
                            serviceDetails.category_dynamic &&
                            JSON.parse(
                              serviceDetails.category_dynamic
                            ).desc.map((desc, i) => (
                              <tr key={i}>
                                <td style={{ textAlign: "left" }}>{desc}</td>
                                <td
                                  style={{ textAlign: "right", width: "30%" }}
                                >
                                  {currency}{" "}
                                  {
                                    JSON.parse(serviceDetails.category_dynamic)
                                      .price[i]
                                  }
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>

                    <ul className="chart-ul">
                      {renderTerms(rateTermsCondition)}
                    </ul>

                    <ul className="chart-ul">
                      {renderTerms(cancellationDesc)}
                    </ul>

                    <ul className="chart-ul">
                      <li>{serviceDetails.min_amt_txt_lang_1}</li>
                      {/* <li>
                        Inspection charge of ₹ 150 will be charged in case the
                        customer refuses to proceed with service, after
                        inspection.
                      </li>*/}
                    </ul>
                  </div>
                </div>
              </div>

              <div
                className="panel panel-default"
                style={{
                  display: serviceDetails.conditions_lang_1 ? "block" : "none",
                }}
              >
                <div className="panel-heading" role="tab" id="headingOne">
                  <h4 className="panel-title">
                    <a
                      className="collapsed last"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseOne"
                      aria-expanded="false"
                      aria-controls="collapseOne"
                    >
                      Terms &amp; Conditions <span> </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseOne"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingOne"
                  data-parent="#accordion"
                >
                  <div className="panel-body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          serviceDetails.conditions_lang_1
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="panel panel-default"
                style={{
                  display: serviceDetails.how_it_works_lang_1
                    ? "block"
                    : "none",
                }}
              >
                <div className="panel-heading" role="tab" id="headingTwo">
                  <h4 className="panel-title">
                    <a
                      className="collapsed last"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                    >
                      How it works <span> </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseTwo"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingTwo"
                  data-parent="#accordion"
                >
                  <div className="panel-body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(
                          serviceDetails.how_it_works_lang_1
                        ),
                      }}
                    />
                  </div>
                </div>
              </div>

              <div
                className="panel panel-default"
                style={{
                  display: serviceDetails.faq_lang_1 ? "block" : "none",
                }}
              >
                <div className="panel-heading" role="tab" id="headingThree">
                  <h4 className="panel-title">
                    <a
                      className="collapsed last"
                      role="button"
                      data-toggle="collapse"
                      data-parent="#accordion"
                      href="#collapseThree"
                      aria-expanded="false"
                      aria-controls="collapseThree"
                    >
                      FAQs <span> </span>
                    </a>
                  </h4>
                </div>
                <div
                  id="collapseThree"
                  className="panel-collapse collapse"
                  role="tabpanel"
                  aria-labelledby="headingThree"
                  data-parent="#accordion"
                >
                  <div className="panel-body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: DOMPurify.sanitize(serviceDetails.faq_lang_1),
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-sm-12 mb-5">
            <div
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(serviceDetails.description_lang_1),
              }}
            />
          </div>
          <div className="col-sm-12">
            <ul className="seo-tag">
              {coveredArea.map((area, index) => (
                <li key={index}>
                  {" "}
                  <span className="text-capitalize">
                    {serviceDetails.service_name}
                  </span>{" "}
                  in {area.caption}{" "}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-sm-12 mb-5">
            <div
              className="inner-page-deal-fix-header deal-fix-header"
              id="myBtn"
            >
              <div className="container">
                <div className="event-detail-fix-wrapper" dir="">
                  <div className="row">
                    <div className="col-12 text-right">
                      <div className="event-register">
                        <a
                          href={`${serviceDetails.url}/services/${serviceDetails.slug_url}/${serviceDetails.seo_url}?openmodal=1`}
                          className="btn detail-reg-btn"
                        >
                          <span className="arrowCta"></span>Proceed
                          <span className="arrowCta2"></span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServicesDetails;
