import React, { useEffect, useState } from "react";

import { useSelector } from "react-redux";

import ReCAPTCHA from "react-google-recaptcha";


function BookModal() {
  const { apiKey, baseUrl } = useSelector((state) => state.settings);

  const [countries, setCountries] = useState([]);

  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await fetch(`${baseUrl}/api/country/list`, {
          method: "GET",
          headers: {
            Authorization: "Basic YWRtaW46MTIzNA==",
            session: apiKey,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const result = await response.json();
        if (result.status === "success") {
          setCountries(result.data); 
        } else {
          throw new Error("Failed to fetch countries");
        }
      } catch (error) {
      } finally {
      }
    };

    fetchCountries();
  }, [apiKey, baseUrl]);

  const cities = [
    {
      ID: "11",
      category: "2",
      city_name: "Baku",
      link: "https://joboy.az/home/baku",
    },
    {
      ID: "1",
      category: "3",
      city_name: "London",
      link: "https://joboy.ca/home/london",
    },
    {
      ID: "2",
      category: "3",
      city_name: "Toronto",
      link: "https://joboy.ca/home/toronto",
    },
    {
      ID: "3",
      category: "3",
      city_name: "Calgary",
      link: "https://joboy.ca/home/calgary",
    },
    {
      ID: "4",
      category: "1",
      city_name: "Delhi NCR",
      link: "https://joboy.in/home/delhi-ncr",
    },
    {
      ID: "5",
      category: "1",
      city_name: "Kochi",
      link: "https://joboy.in/home/kochi",
    },
    {
      ID: "6",
      category: "1",
      city_name: "Mumbai",
      link: "https://joboy.in/home/mumbai",
    },
    {
      ID: "7",
      category: "1",
      city_name: "Chennai",
      link: "https://joboy.in/home/chennai",
    },
    {
      ID: "8",
      category: "1",
      city_name: "Trivandrum",
      link: "https://joboy.in/home/trivandrum",
    },
    {
      ID: "9",
      category: "1",
      city_name: "Hyderabad",
      link: "https://joboy.in/home/hyderabad",
    },
    {
      ID: "11",
      category: "1",
      city_name: "Thrissur",
      link: "https://joboy.in/home/thrissur",
    },
    {
      ID: "13",
      category: "1",
      city_name: "Kozhikode",
      link: "https://joboy.in/home/kozhikode",
    },
    {
      ID: "14",
      category: "1",
      city_name: "Bengaluru",
      link: "https://joboy.in/home/bengaluru",
    },
    {
      ID: "15",
      category: "5",
      city_name: "Dubai",
      link: "https://joboy.ae/home/dubai",
    },
    {
      ID: "16",
      category: "5",
      city_name: "Sharjah",
      link: "https://joboy.ae/home/sharjah",
    },
    {
      ID: "17",
      category: "5",
      city_name: "Abu Dhabi",
      link: "https://joboy.ae/home/abu-dhabi",
    },
    {
      ID: "18",
      category: "4",
      city_name: "Greater London",
      link: "https://joboy.uk/home/greater-london",
    },
    {
      ID: "19",
      category: "7",
      city_name: "Johannesburg",
      link: "https://joboy.co/home/johannesburg",
    },
    {
      ID: "20",
      category: "7",
      city_name: "Cape Town",
      link: "https://joboy.co/home/cape-town",
    },
    {
      ID: "21",
      category: "7",
      city_name: "Garden Route",
      link: "https://joboy.co/home/garden-route",
    },
  ];

  const [cityOptions, setCityOptions] = useState([]);

  const handleCategoryChange = (event) => {
    const category = event.target.value;
    const filteredCities = cities.filter((city) => city.category === category);
    setCityOptions(filteredCities);
  };

  const handleCombinedChange = (e) => {
    handleCategoryChange(e);
    handleChange(e);
    handleSelectChange(e)
  };

  const initialFormData = {
    country: '',
    country_name: '',
    city: '',
    city_name: '',
    name: '',
    email: '',
    mobile: '',
    message: ''
  };

  const [formData, setFormData] = useState(initialFormData);

  const [showModal, setShowModal] = useState(false); // Modal visibility state
  const [successMessage, setSuccessMessage] = useState(""); // Success message state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSelectChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const country = selectedOption.value;
    const countryName = selectedOption.getAttribute('data-name');

    setFormData((prevData) => ({
      ...prevData,
      country,
      country_name: countryName,
    }));
  };

  const handleCityChange = (e) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const city = selectedOption.value;
    const cityName = selectedOption.getAttribute('data-name');

    setFormData((prevData) => ({
      ...prevData,
      city,
      city_name: cityName,
    }));
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  //console.log('Form submitted:', formData);

  if (!captchaValue) {
    alert("Please verify that you are not a robot");
  } else {

    fetch(`${baseUrl}/api/enquiry/submit`, {
      method: "POST",
      headers: {
        Authorization: "Basic YWRtaW46MTIzNA==",
        session: apiKey,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Success:", data);
        setSuccessMessage("We have recieved your order. Our team will contact you soon."); // Set success 
        setFormData(initialFormData); // Clear form data
        setTimeout(() => {
          setShowModal(false); // Close modal after 2 seconds
          setSuccessMessage(""); // Clear success message
        }, 2000);
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    }
  };

  return (
    <div>
      <a
        className="floating"
        href="#quickBook"
        onClick={(e) => {
          e.preventDefault();
          setShowModal(true);
        }}
        title="Quick Booking"
      >
         <i class="fa-solid fa-calendar-plus fab-icon"></i>
      </a>

      {showModal && (
        <>
          <div className="modal-backdrop fade show"></div>
          <div
            id="quickBook"
            className="modal fade show"
            aria-modal="true"
            style={{ display: "block" }}
          >
            <div className="modal-dialog modal-md">
              <div className="modal-content">
                <div className="modal-body">
                  <button
                    type="button"
                    className="close"
                    aria-label="Close"
                    onClick={() => setShowModal(false)}
                  >
                    <img
                      src="https://d27vg8jo26ejl7.cloudfront.net/images/close.jpg"
                      width="24"
                      height="24"
                      alt="close btn"
                    />
                  </button>
                  <h3 className="text-center mb-4"> Book Service </h3>


                  {successMessage ? (
                    <p className="success-message">{successMessage}</p> // Display success message
                  ) : (


                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="country"
                            id="country"
                            onChange={handleCombinedChange}
                            required
                          >
                            <option value=""> country </option>
                            {countries.map((country) => (
                              <option
                                key={country.country_id}
                                value={country.country_id}
                                data-name={country.name}
                              >
                                {country.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <select
                            className="form-control"
                            name="city"
                            id="city"
                            required
                            onChange={handleCityChange}
                          >
                            <option value=""> city </option>
                            {cityOptions.map((city) => (
                              <option key={city.ID} value={city.ID} data-name={city.city_name}>
                                {city.city_name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="name"
                            placeholder="Name."
                            required
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="text"
                            className="form-control"
                            name="mobile"
                            placeholder="Mobile."
                            required
                            value={formData.mobile}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Email."
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <textarea
                            id="message"
                            name="message"
                            value={formData.message}
                            className="form-control"
                            rows="4"
                            placeholder="Comments.."
                            onChange={handleChange}
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <ReCAPTCHA
                            sitekey="6LfcZokqAAAAAFRU_u0pGe6Y7djgegNUFLLHTW1H" // Replace with your site key
                            onChange={handleCaptchaChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group">
                          <button
                            className="btn submit-now-btn btn-block"
                            type="submit"
                          >
                            SUBMIT
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>

                        )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default BookModal;
