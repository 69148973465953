import React from "react";

import ExploreServices from "./ExploreServices";
import { Helmet, HelmetProvider } from "react-helmet-async";
import BookModal from "./BookModal";



const HvacServiceNearMe = () => {

    return (
        <div>
            <HelmetProvider>
        <Helmet>
          <title>Your Trusted HVAC Professionals in | Book Heating & Cooling near me  </title>
          <meta name="description" content="Discover top-notch HVAC services for residential and commercial needs in your city. Our experienced team offers installation, maintenance, and repair to ensure your indoor comfort year-round. Contact us now on Joboy for reliable, efficient solutions." />
          <meta name="keywords" content="hvac services near me, top hvac companies near me, hvac maintenance companies near me, ac repairnear me, air conditioning companies near me, ac maintenance services near me, air conditioning service near me, air conditioning repair near me " />
        </Helmet>
      </HelmetProvider>

            <section>
                <img src="../joboyimages/services/hvac-hero.jpg" className="img-fluid" alt="Expert AC Services Near You" />
            </section>

            <section>
                <div className="container text-center pb40">
                    <h1 className="serviceh1">FIND EXPERT AIR CONDITIONER REPAIRS NEAR YOU</h1>
                    <p>HVAC repairs near you, at your convenient time. Book now for AC installation, repairs, uninstallation, gas charging, HVAC cleaning, etc.</p>
                </div>
                <div className="container pt40 pb40">
                <h2 className="text-center heading_h2">QUICK LINKS TO HVAC SERVICES IN CITIES AROUND YOU</h2>
                    <div className="row justify-content-center">
                        <ul className="joboy-city">

                        <li> <a href={`/in/ac-service-in-chennai`}><img src="../joboyimages/chennai.png" className="img-icon" alt="Best AC Services in Chennai" />
                                <div>AC Services in </div>
                                <div>Chennai</div>
                            </a> </li>
                            <li> <a href={`/in/ac-service-in-kochi`}><img src="../joboyimages/Kochi.png" className="img-icon" alt="Best AC Services in Kochi" />
                                <div>AC Services in </div>
                                <div>Kochi</div>
                            </a> </li>
                            <li> <a href={`/in/ac-service-in-bangalore`}><img src="../joboyimages/bangalore.png" className="img-icon" alt="Best AC Services in Bengaluru" />
                                <div>AC Services in </div>
                                <div>Bengaluru</div>
                            </a> </li>
                            <li> <a href={`/in/ac-service-in-trivandrum`}><img src="../joboyimages/trivandrum.png" className="img-icon" alt="Best AC Services in Trivandrum" />
                                <div>AC Services in</div>
                                <div>Trivandrum</div>
                            </a> </li>
                           <li> <a href={`/in/ac-repair-in-mumbai`}><img src="../joboyimages/mumbai.png" className="img-icon" alt="Best AC Services in Mumbai" />
                                <div>AC Service in</div>
                                <div>Mumbai</div>
                            </a></li>
                              <li> <a href={`/in/ac-service-in-delhi`}><img src="../joboyimages/Delhi.png" className="img-icon" alt="Best AC Services in Delhi" />
                                <div>AC Services in </div>
                                <div>Delhi</div>
                            </a></li>
                              <li> <a href={`/in/ac-service-in-kozhikode`}><img src="../joboyimages/kozhikode.png" className="img-icon" alt="Best AC Services in Kozhikode" />
                                <div>AC Services in </div>
                                <div>Kozhikode</div>
                            </a></li>

                            <li> <a href={`/in/ac-service-in-thrissur`}><img src="../joboyimages/thrissur.png" className="img-icon" alt="Best AC Services in Thrissur" />
                                <div>AC Services in </div>
                                <div>Thrissur</div>
                            </a> </li>
                            <li> <a href={`/in/ac-service-in-hyderabad`}><img src="../joboyimages/hyderabad.png" className="img-icon" alt="Best AC Services in Hyderabad" />
                                <div>AC Services in </div>
                                <div>Hyderabad</div>
                            </a></li>
                            <li> <a href={`/ae/ac-service-in-dubai`}><img src="../joboyimages/dubai.png" className="img-icon" alt="Best AC Services in Dubai" />
                                <div>AC Services in</div>
                                <div>Dubai</div>
                            </a> </li>
                            <li> <a href={`/ae/ac-service-in-abudhabi`}><img src="../joboyimages/Abudhabi.png" className="img-icon" alt="Best AC Services in Abu Dhabi" />
                                <div>AC Services in </div>
                                <div>Abu Dhabi</div>
                            </a></li>
                            <li> <a href={`/ae/ac-service-in-sharjah`}><img src="../joboyimages/Sharjah.png" className="img-icon" alt="Best AC Services in Sharjah" />
                                <div>AC Services in </div>
                                <div>Sharjah</div>
                            </a></li>
                        </ul>

                    </div>
                </div>
            </section>
            <section>
                <div className="container blue-bg pt40 pb40">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6">
                                <h5 className="download-text white-font"> GET A BETTER EXPERIENCE ON THE JOBOY MOBILE APP. DOWNLOAD NOW!</h5>
                            </div>
                            <div clas="col-md-6">
                                <div className="row">
                                    <div className="col-md-6 form-inline">
                                        <div className="form-group p-1"> <a href="https://apps.apple.com/us/app/joboy-home-services-repairs/id1270174460" className="btn btn-market btn--with-shadow" > <img className="utouch-icon" src="../joboyimages/ios.png" alt="Joboy on Apple app store" />
                                            <div className="text"> <span className="sup-title">Download from</span> <span className="title">App Store</span> </div>
                                        </a>
                                        </div>
                                    </div>
                                    <div className="col-md-6 form-inline">
                                        <div className="form-group p-1"> <a href="https://play.google.com/store/apps/details?id=com.serville.joboy" className="btn btn-market btn--with-shadow"> <img className="utouch-icon" src="../joboyimages/google-play.svg" alt="Joboy on Google play store" />
                                            <div className="text"> <span className="sup-title">Download from</span> <span className="title">Google Play</span> </div>
                                        </a> </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pb40 pt40">
                <div className="container text-center">
                    <h2>EXPERT HVAC SERVICES IN YOUR CITY</h2>
                    <p className="mb-5">Our Air Conditioning services cover installation an uninstallatin of all types of air conditioners from one small single system to large multi VRF/VRV systems. We also provide cleaning, gas charging, periodic maintenance, and other repair services for your HVAC systems, at affordable prices, using best quality spares and materials.</p>

                    <div className="row justify-content-center">
                        <div className="col-lg-12">

                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-item pd-bottom-30">
                                <div className="thumb">
                                    <img src="../joboyimages/services/ac-installation.jpg" alt="AC installation and uninstallation" />
                                </div>
                                <div className="featured-item-meta">
                                    <h3>AC INSTALLATION</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-item pd-bottom-30">
                                <div className="thumb">
                                    <img src="../joboyimages/services/ac-repair.jpg" alt="AC cleaning and repairs" />
                                </div>
                                <div className="featured-item-meta">
                                    <h3>AC REPAIRS</h3>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="featured-item pd-bottom-30">
                                <div className="thumb">
                                    <img src="../joboyimages/services/ac-gasfilling.jpg" alt="AC gas filling" />
                                </div>
                                <div className="featured-item-meta">
                                    <h3>AC GAS FILLING</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </section>
            <section>
                <div className="container text-center">
                    <div className="row">
                        <div className="col-sm-4 pt-5">
                            <ul className="features-list">
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Easy-Scheduling.png" alt="Easy to book" /> </div>
                                <li className="single-feature-item">
                                    <h5> Your Convenient Time</h5>
                                    <span className="text">Your time is precious. Leave the tough work to us.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Verified-Professionals.png" alt="Background verified" /></div>
                                <li className="single-feature-item">
                                    <h5>Verified Service Partners</h5>
                                    <span className="text">Your safety and security is important for us.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/ServiceWarranty.png" alt="Warranty on services" /> </div>
                                <li className="single-feature-item">
                                    <h5>Service Warranty</h5>
                                    <span className="text">Get upto 60 days service warranty ensuring peace of mind.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/TransparentPricing.png" alt="Pre-defined pricing" /> </div>
                                <li className="single-feature-item">
                                    <h5>Transparent Pricing</h5>
                                    <span className="text">Know in advance what you are paying for.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Secure-Payments.png" alt="Many payment options" /> </div>
                                <li className="single-feature-item">
                                    <h5>Online Payments </h5>
                                    <span className="text">Don't carry cash? No problem, pay online.</span> </li>
                                <div className="iconbox_icon"> <img className="first_icon" src="../joboyimages/Support.png" alt="Best customer care" /> </div>
                                <li className="single-feature-item">
                                    <h5>24 x 7 Support</h5>
                                    <span className="text">Reach us anytime. We are here to support.</span> </li>
                            </ul>
                        </div>
                        <div className="col-sm-8 text-left">
                            <div className="p-5">
                                <h3>Why book AC technicians from Joboy?</h3>
                                <div>
                                    <p>We bring you background verified, experienced, Heating, Ventilation, Air Conditioner service technicians to you, at your most preferred time, with a service warranty. You can be sure that we will give you the best services, and in case something goes wrong with what we have done, we will correct it at our cost. Our service costs are affordable, and you get to know in advance what you will be paying for. You also have the option to pay by cash or pay online. Rest assured, your appliance is safe in our hands.</p>
                                    <p className="mt-3 mb-5">Book a service online easily, sit back and relax, and let our experts take care of your home.</p>
                                </div>
                                <div className="clearfix"></div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <ul className="td-list mb-5">
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Best Electricians Near Me" /></i>AC Servicing</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Electrical Repairs Near Me" /></i>AC Installation</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Electrical wiring fixes" /></i>AC Ducting</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Inverter and stabilizer services" /></i>AC Duct Cleaning</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Fan and air cooler repairs" /></i>AC Annual Maintenance</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="MCB and fuse box repairs" /></i>Thermostat Installation</li>
                                        </ul>
                                    </div>
                                    <div className="col-sm-6">
                                        <ul className="td-list mb-5">
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Switch and sockets repairs" /></i>AC Compressor Repairing</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Light fixtures repairs" /></i>AC Gas Charging</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Room heater repairs" /></i>AC Filter Cleaning</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="HVAC electrical fitting" /></i>AC Thermostat Repairing</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Wall drilling and hanging" /></i>AC Uninstallation Near</li>
                                            <li><i><img src="../joboyimages/tck-mark.png" alt="Accessories installation" /></i>AC Replacement</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ExploreServices />
            <BookModal />


        </div>
    );
}


export default HvacServiceNearMe;